import React from "react";
import theme from "theme";
import { Theme, Icon, Text, Box, Section } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
import { MdPhone } from "react-icons/md";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
			WheelEase Rentals
			</title>
			<meta name={"description"} content={"Плавные поездки, легкое вождение - WheelEase, ваше путешествие начинается здесь"} />
			<meta property={"og:title"} content={"WheelEase Rentals"} />
			<meta property={"og:description"} content={"Плавные поездки, легкое вождение - WheelEase, ваше путешествие начинается здесь"} />
			<meta property={"og:image"} content={"https://groxina.com/img/1.jpg"} />
			<link rel={"shortcut icon"} href={"https://groxina.com/img/3224646.jpg"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://groxina.com/img/3224646.jpg"} />
			<link rel={"apple-touch-startup-image"} href={"https://groxina.com/img/3224646.jpg"} />
			<meta name={"msapplication-TileImage"} content={"https://groxina.com/img/3224646.jpg"} />
		</Helmet>
		<Components.Header />
		<Section padding="90px 0 100px 0" quarkly-title="Schedule-4">
			<Override slot="SectionContent" border-color="--primary" color="--primary" />
			<Icon
				category="md"
				icon={MdPhone}
				size="84px"
				align-self="center"
				margin="0px 0px 25px 0px"
				color="--primary"
			/>
			<Text margin="0px 0px 25px 0px" text-align="center" font="normal 500 42px/1.2 --fontFamily-sans" color="--primary">
			Свяжитесь с WheelEase Rentals
			</Text>
			<Text
				text-align="center"
				font="normal 300 16px/1.5 --fontFamily-sansTrebuchet"
				color="#707375"
				width="700px"
				align-self="center"
				margin="0 0px 50px 0px"
				lg-width="100%"
				md-margin="0 0px 35px 0px"
			>
				Нужна помощь или есть вопрос? Наша служба поддержки всегда готова помочь вам с любыми вопросами. Свяжитесь с нами по следующим каналам для оперативной помощи:
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				color="#707375"
				margin="0px 0px 80px 0px"
				max-width="100%"
			>
				<Text
					margin="0px 0px 15px 0px"
					text-align="center"
					font="500 28px/1.5 --fontFamily-sansTrebuchet"
					color="--primary"
					border-color="--primary"
				>
					+7 727 330 87 71
				</Text>
				<Text
					margin="0px 0px 15px 0px"
					text-align="center"
					font="500 28px/1.5 --fontFamily-sansTrebuchet"
					color="--primary"
					border-color="--primary"
				>
					contact@flixuso.com
				</Text>
			</Box>
			<Box min-width="100px" min-height="100px" display="flex" sm-flex-direction="column">
				<Box
					min-width="100px"
					min-height="100px"
					width="50%"
					display="flex"
					flex-direction="column"
					align-items="center"
					border-width="2px"
					border-style="solid"
					border-color="--color-primary"
					margin="0 auto"
					padding="40px 50px 40px 50px"
					sm-width="100%"
					md-padding="20px 20px 20px 20px"
				>
					<Text margin="0px 0px 10px 0px" text-align="center" font="normal 300 20px/1.5 --fontFamily-sansTrebuchet" color="#707375">
					ул. Сатпаева, 90/5, Алматы 
					</Text>
					<Text margin="0px 0px 30px 0px" text-align="center" font="normal 300 20px/1.5 --fontFamily-sansTrebuchet" color="#707375">
					050000, Казахстан
					</Text>
				</Box>
			</Box>
		</Section>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});